<template>
	<div class="express">
		<div class="bg"></div>
		<div class="wrap">
			<div class="info e-d">
				<div class="wrap flex f-jc-sb">
					<span>运单号</span>
					<span>{{logisticsOrderNo}}</span>
				</div>
				<div class="wrap flex f-jc-sb">
					<span>国内承运人</span>
					<span>{{logisticsName}}</span>
				</div>
			</div>
			<div class="detail e-d">
				<ul class="wrap">
					<li v-for="(item, index) in expressList" :key="index" class="flex f-d-c"
						:class="[!index ? `active-${item.statusNum}` : `g-${item.statusNum}`, index === expressList.length - 1 ? 'no-border' : '']"
					>
						<p>{{item.status}}</p>
						<div v-for="(ite, ind) in item.list" :key="ind" class="flex f-d-c">
							<span>{{ite.context}}</span>
							<span>{{ite.time}}</span>
						</div>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>
<script>
import { expressQuery } from '@/request/api/user';
// 订单状态：0在途，1揽收，2疑难，3签收，4退签，5派件，6退回，7转单，10待清关，11清关中，12已清关，13清关异常，14收件人拒签   等13个状态
export default {
	name: 'expressDetail',
	data () {
		return {
			expressList: [],
			logisticsName: '',
			logisticsOrderNo: ''
		};
	},
	created () {
		this.getExpressQuery();
	},
	methods: {
		getExpressQuery () {
			const _map = {
				在途: 0,
				揽收: 1,
				签收: 3,
				派件: 5
			};
			expressQuery({ id: this.$route.query.appointmentId }).then(data => {
				// console.log(data);
				for (const i of data.expressList) {
					i.statusNum = _map[i.status];
				}
				this.expressList = data.expressList;
				this.logisticsName = data.logisticsName;
				this.logisticsOrderNo = data.logisticsOrderNo;
			});
		}
	}
};
</script>
<style lang="less" scoped>
	.express {
		position: relative;
		.bg {
			position: absolute;
			z-index: -1;
			width: 100%;
			height: 150px;
			background: url('../../assets/images/rectangle.png') no-repeat;
			background-size: cover;
		}
		.wrap {
			padding-top: 16px;
			.e-d {
				background: #FFFFFF;
				box-shadow: 0px 4.5px 18px -4px rgba(16, 31, 46, 0.04);
				border-radius: 8px;
			}
			.info {
				height: 83px;
				font-size: 14px;
				div {
					span:nth-child(1) {
						color: #7D7D7D;
					}
					span:nth-child(2) {
						font-weight: 500;
					}
				}
			}
			.detail {
				margin-top: 10px;
				ul {
					font-size: 13px;
					li {
						// padding-bottom: 30px;
						padding-left: 17px;
						border-left: 1px solid #BEBEBE;
						position: relative;
						p {
							font-size: 15px;
							font-family: PingFangSC-Medium, PingFang SC;
							font-weight: 500;
							color: #232830;
						}
						div {
							margin-bottom: 31px;
							span:nth-child(2) {
								margin-top: 12px;
								color: #7D7D7D;
								font-size: 12px;
							}
						}
						&::before {
							content: '';
							display: inline-block;
							width: 18px;
							height: 18px;
							border-radius: 50%;
							position: absolute;
							left: -10px;
							top: 0;
							background-repeat: no-repeat;
							background-size: contain;
						}
						&.g-0::before {
							background-image: url('../../assets/images/ysz-g.png');
						}
						&.g-1::before {
							background-image: url('../../assets/images/ylj-g.png');
						}
						&.g-5::before {
							background-image: url('../../assets/images/psz-g.png');
						}
						&.active-0::before {
							background-image: url('../../assets/images/ysz-r.png');
						}
						&.active-1::before {
							background-image: url('../../assets/images/ylj-r.png');
						}
						&.active-3::before {
							background-image: url('../../assets/images/yqs-r.png');
						}
						&.active-5::before {
							background-image: url('../../assets/images/psz-r.png');
						}
						&.no-border {
							border-left: none;
						}
					}
				}
			}
		}
	}
</style>